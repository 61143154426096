<template>
  <div class="test">
    <div class="center-title">我的考试</div>
    <div class="btns">
      <button class="button" :class="tabIndex==1?'blue':'grey'" @click="tabIndex=1">班级课程</button>
      <button class="button" :class="tabIndex==2?'blue':'grey'" @click="tabIndex=2">班级考试</button>
      <button class="button" :class="tabIndex==3?'blue':'grey'" @click="tabIndex=3">班级考试</button>
    </div>
    <ul class="list">
      <li class="info">
        <span class="info-title test">考试名称考试名称</span>
        <span class="info-type">考试类型：<span class="test">班级考试</span></span>
        <span class="info-date">考试时间：<span class="test">2021年11月26日</span></span>
        <span class="info-to-test">去考试</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'MyTest',
  data(){
    return {
      tabIndex: 1,  //标签页
    }
  }
}
</script>

<style scoped lang="scss">
.btns{
  .button{
    margin-right: 14px;
  }
}
.list{
  margin-top: 34px;
}
.info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  height: 56px;
  background: #F5F5F5;
  border-radius: 2px;
  font-size: 12px;
  color: #999;
  .test{
    color: #333;
  }
}
</style>
